<template>
  <AppLayout wrapperClass="cart">
    <Breadcrumbs class="my-30" />
    <section class="cart pt-8">
      <b-container class="mb-20">

        <span class="page-title font-weight-normal d-none d-md-block mb-50">{{ $t("shopping_basket") }}</span>

        <div class="d-flex justify-content-between align-items-center cart-info-and-btn">
          <div class="items-info font-weight-normal d-block text-blue" v-html="getItemsInCart"></div>
          <div class="options ml-20 d-none d-md-block">
            <b-link href="/">{{ $t("continue_shopping") }}</b-link>
            <b-button @click="$router.push('/checkout')" variant="warning" class="text-light py-6 pr-20 pl-15 rounded">
              {{ $t("continue_buying") }}
            </b-button>
          </div>
        </div>

        <b-row v-for="cartItem, index of cartItems" :key="cartItem.id" class="cart-item-row position-relative"
          :class="cartItems.length == index + 1 ? 'cart-item-last-row' : ''">
          <b-link :to="cartItem.product.url_key" class="d-md-none mobile-cart-item-remove">
            <i class="lnr lnr-cross"></i>
          </b-link>

          <b-col md="10" class="d-flex">
            <a class="product-image border border-info">
              <b-img class="" :src="cartItem.product.image.medium"> </b-img>
            </a>
            <div class="d-flex flex-column flex-md-row justify-content-between align-items-md-center flex-grow-1">
              <div class="product">
                <div>
                  <b-link :to="'/' + cartItem.product.url_key" class="">
                    <h4>{{ cartItem.product.name }} </h4>
                  </b-link>
                </div>
                <div>
                  <span><strong>Produktnummer:</strong> {{ cartItem.product.sku }}</span>
                  <span><strong>Inhalt:</strong> {{ cartItem.product.inhalt }}</span>
                  <span v-html="formateofGramm(cartItem.product)"></span>
                  <span>Preise exkl. MwSt. zzgl.</span>
                </div>
                <div>
                  <b-link class="link"> Klicken Sie hier, um zu erfahren, was Sie tun möchten </b-link>
                </div>

                <!-- <div v-if="typeof cartItem.configurable_options != 'undefined'">
                  <div v-for="(option, index) of cartItem.configurable_options" :key="index"
                    class="d-flex align-items-center mb-7">
                    <span class="size">{{ option.option_label }}: </span>
                    <span class="size-box">{{ option.value_label }}</span>
                    <span :style="'color: ' +
                      cartItem.configured_variant.DeliveryTime.color +
                      ' !important'
                      " class="deliverytime" v-if="
                        typeof cartItem.configured_variant.DeliveryTime !==
                        undefined
                      ">{{ cartItem.configured_variant.DeliveryTime.long }}</span>
                    <span class="product--price text-blue d-md-none ml-20 config">{{
                      formatCurrency(
                        cartItem.prices.row_total_including_tax.value
                      )
                    }}</span>
                  </div>
                  <b-link class="change-price text-blue d-none d-md-inline-block"
                    @click="updateCartProduct(cartItem)">{{ $t("click_to_change_options") }}</b-link>
                </div>
                <div v-if="typeof cartItem.configurable_options == 'undefined'">
                  <span :style="'color: ' +
                    cartItem.product.DeliveryTime.color +
                    ' !important'
                    " class="deliverytime" v-if="typeof cartItem.product.DeliveryTime !== undefined">{{
                      cartItem.product.DeliveryTime.long }}</span>
                </div> -->
              </div>

              <div class="quantity-input d-flex mx-md-20 border-0">
                <b-link class="step-down-btn btn-warning rounded-left"
                  @click="quantityDown(cartItem.id, cartItem.quantity)"></b-link>
                <input type="number" class="border border-right-0 border-left-0" min="1" :value="cartItem.quantity"
                  name="quantity" ref="inputQuantity" disabled />
                <b-link class="step-up-btn btn-warning rounded-right"
                  @click="quantityUp(cartItem.id, cartItem.quantity)"></b-link>
              </div>
            </div>
          </b-col>
          <b-col md="1"></b-col>

          <b-col md="1" class="d-none d-md-flex align-items-center justify-content-center position-relative pr-55">
            <span class="product--price text-danger mr-10 ml-30">
              &nbsp;{{ formatCurrency(cartItem.prices.row_total_including_tax.value) }}*</span>
            <b-link class="pt-6 " href="#" @click="removeItem(cartItem.id)">
              <font-awesome-icon :icon="['fas', 'xmark']" size="2x" />
            </b-link>
          </b-col>

        </b-row>
        <b-row class="discount-and-total">

          <b-col md="5" lg="4" class="coupon-discount">
            <span class="d-block">{{ $t("coupon_discount") }}</span>

            <form action="#" class="d-flex ">
              <input type="text" class="flex-grow-1 border border-warning" name="discount-code"
                :placeholder="$t('fill_out_discount_code')" v-model="couponCode" />
              <b-button @click="addCoupon" variant="warning ">{{ $t("apply") }}</b-button>
            </form>

            <div v-if="appliedCouponCodes" class="pt-10">
              {{ $t("applied_coupon_code") }}:
              <!-- {{ appliedCouponCodes[0].code }} -->
              <b-link href="#" @click="removeCouponCode()" class="lnr lnr-cross"></b-link>
            </div>

            <div class="free_shipping">
              {{ $t("free_shipping_msg", { remain: formatCurrency(freeShippingAmount), }) }}
            </div>

          </b-col>
          <b-col md="6" xl="5" offset-md="1" offset-lg="2" offset-xl="3" class="cost">

            <span class="cost-heading font-weight-normal d-block">{{ $t("totals") }}</span>

            <ul class="prices">
              <li v-if="defaultShipping != null" class="d-flex justify-content-between">
                <span class="name">{{ $t("shipping_cost") }} {{ defaultShipping.carrier_title }}</span>
                <span v-if="typeof defaultShipping.amount != 'undefined'" class="cost ml-10">
                  {{ formatCurrency(defaultShipping.amount.value) }}</span>
              </li>

              <li class="d-flex justify-content-between" v-for="(discount, index) in cartTotalDiscounts" :key="index">
                <span class="name">{{ discount.label }}</span>
                <span class="cost ml-10">- {{ formatCurrency(discount.amount.value) }}</span>
              </li>

              <li class="d-flex justify-content-between" v-for="(price, index) in cartTotalTaxPrices" :key="index">
                <span class="name">{{ price.label }}</span>
                <span class="cost ml-10">{{ formatCurrency(price.amount.value) }}</span>
              </li>

            </ul>

            <div class="d-flex justify-content-between align-items-center mb-25">
              <span class="total">{{ $t("total") }}</span>
              <span class="total-price ml-10 text-light-blue">{{ formatCurrency(cartTotalPrice) }}</span>
            </div>

            <div class="time-delivery" v-if="getCartDeliveryTime"> {{ $t("estimated_shipping_time") }}:
              <span :style="'color: ' + getCartDeliveryTime.color + ' !important'">{{ getCartDeliveryTime.long }}</span>
            </div>

            <div class="d-flex justify-content-end align-items-center">
              <b-link to="/" class="mr-15 d-md-none link-underline">{{ $t("continue_shopping") }}</b-link>
              <b-button @click="$router.push('/checkout')" variant="warning" class="text-light py-6 pr-20 pl-15">
                {{ $t("continue_buying") }}</b-button>
            </div>

          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="more-products" v-if="getCrossSellProducts && getCrossSellProducts.length > 0">
      <div class="container">
        <span class="section-title d-block font-weight-normal text-center">Klanten bestelden ook</span>
        <ClientOnly>
          <MoreProductsCarousel class="pt-5" :products="getCrossSellProducts" />
        </ClientOnly>
      </div>
    </section>
  </AppLayout>
</template>

<script>
import AppLayout from "@/esf_kerkrade_vitanatura/core/components/AppLayout.vue";
import Breadcrumbs from "@/esf_kerkrade_vitanatura/core/components/Breadcrumbs";
import MoreProductsCarousel from "@/base/core/components/MoreProductsCarousel";
import ClientOnly from "vue-client-only";
import Cart from "@storefront/core/modules/cart/mixins";
import config from "@config";

export default {
  name: "CartPage",
  components: {
    AppLayout,
    Breadcrumbs,
    MoreProductsCarousel,
    ClientOnly,
  },
  mixins: [Cart],
  data() {
    return {
      couponCode: "",
    };
  },
  computed: {
    freeShippingAmount() {
      return this.$store.getters["cart/getFreeShippingAmount"];
    },
    getItemsInCart() {
      let items = this.$t("items_in_cart", { numberOf: this.cartCount });
      items =
        '<i class="lnr lnr-cart mr-7"></i>' +
        items.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
      return items;
    },
    getCartDeliveryTime() {
      return this.$store.getters["cart/getCartDeliveryTime"];
    },
    appliedCouponCodes() {
      return this.$store.getters["cart/getAppliedCouponCodes"];
    },
    getCrossSellProducts: {
      get() {
        return this.$store.getters["sale/getCrossSellProducts"];
      },
      set() { },
    },
  },
  methods: {
    async addCoupon() {
      if (this.couponCode != "") {
        const retval = await this.$store.dispatch("cart/addCouponCode", {
          code: this.couponCode,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("coupon_code"),
            text: this.$t("coupon_code_ok", { code: this.couponCode }),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.appliedCouponCode = this.couponCode;
          this.couponCode = "";
        }
      }
    },
    getCartItemUrl(url_key) {
      return "/" + url_key;
    },
    formateofGramm(data) {
      const final_calcultion = parseFloat(data.price_range.minimum_price.final_price.value) / parseInt(data.verkaufseinheit) * parseInt(data.grundeinheit);
      const text = `${data.verkaufseinheit} Gramm (${(final_calcultion).toFixed(2)} €* / ${data.grundeinheit} Gramm)`
      return text
    },
    updateCartProduct(product) {
      this.removeItem(product.id);
      this.$router.push("/" + product.product.url_key);
    },
    formatCurrency(value) {
      return this.$helpers.formatCurrency(value);

    },
    async removeCouponCode() {
      await this.$store.dispatch("cart/removeCouponCode");
    },
    async updateCrossSellProducts() {
      var skuList = [];
      this.cartItems?.forEach((item) => {
        skuList.push(item.product.sku);
      });
      if (config.helloRetail && config.helloRetail.enabled) {
        await this.$store.dispatch("sale/loadCrossSellProducts", {
          skus: skuList,
        });
      } else {
        await this.$store.dispatch("sale/loadCrossSellForCart", {
          skus: skuList,
        });
      }
      setTimeout(() => {
        this.getCrossSellProducts =
          this.$store.getters["sale/getCrossSellProducts"];
      }, 1000);
    },
  },
  mounted() {
    this.updateCrossSellProducts();
  },
  watch: {
    cartItems() {
      this.updateCrossSellProducts();
    },
  },
};
</script>

<style>
.cart {

  .cart-item-row {

    .link {
      font-size: 20.88px;
      font-weight: 400;
      line-height: 26px;
    }

    .product-image {
      width: 261.05px;
      display: flex;
      height: 217.06px;
      align-items: center;

      img {
        width: 106.74px;
        height: 168.24px;
        object-fit: cover;
        margin-inline: auto;
      }
    }
  }

  .product {
    & {
      margin-left: 6rem;
      display: flex;
      flex-direction: column;
      height: 100% !important;
      justify-content: space-between;
    }

    h4 {
      font-size: 27px;
      font-style: italic;
      font-weight: 700;
      line-height: 35px;
    }

    span {
      display: block;
      font-size: 15px;
      font-weight: 400;
      line-height: 30px;
      color: #7FB800;
    }

    strong {
      font-weight: bolder !important;
    }
  }
}
</style>